$orange: #e74c3c;
$blue: #0a2f64;
$lightblue: #009ee3;

.nav-bar{
	background-color: $blue;
	
	width: 100%;
	
}

.nav-menu {
	width: 100%;
	li {
		a {
			color: #FFF;
			&:hover{
				text-decoration: underline;
			}
		}

		ul{
			list-style: none;
		}
	}
}

.footer{
	background-color: #e0e0e0;
}

h1{
	color: $lightblue;
}

p, li{
	color: #4c4c4c;
}

.orange{
	color: $orange;
}

.blue{
	color: $blue;
}

.lightblue{
	color: $lightblue;
}

.header-panel{
	padding-top:110px;
	padding-bottom:70px;
	background: #000000 url(../../assets/img/home/STRIDE-website-photo.jpg) no-repeat center;
	
	h1, p{
		text-shadow: 1px 1px 0 #000;
	}
	p{
		color: #FFF;

	}
}

.white-panel{
	background-color: #FFF;
	padding-bottom: 20px;
	padding-top: 20px;
}

.grey-panel{
	background-color: rgb(236, 240, 241);
	padding-bottom: 20px; 
	padding-top: 20px;
}

.orange-panel{
	background-color: $orange;
	padding: 10px;
	h4 {
		color: #FFF;
	}
	p{
		color: #000;
	}
	.top-banner{
		color: #FFF;
		margin: 0;
		a {
			color: #FFF;
			&:hover{
				text-decoration: underline;
			}
		}
	}
	.selected {
		color: #000;
		text-decoration: none;
		&:hover{
			text-decoration: underline;
			color: #000;
		}
	}
}



.bio-pic{
	width:100%;
	border: 1px solid $orange;
	margin-bottom: 10px;
}

.bio-pic-thumb{
	margin-bottom:20px;
	border: 1px solid $orange;
	&:hover{
      border: 1px solid $lightblue;
      cursor: pointer;
    }
}

.icon{
	height: 60px;
	padding-top: 10px;
	padding-bottom:10px;
}

.press-thumb{
	height: 80px;
	padding-top: 10px;
	padding-bottom:10px;
}

.logos {
	width: 100%;
	margin-top: 10px;
}

.hexagons{
	&:hover{
		opacity: 0.6;
		cursor: pointer;
	}
}

